import { render, staticRenderFns } from "./SVGIcon.vue?vue&type=template&id=ffc1f706"
import script from "./SVGIcon.vue?vue&type=script&lang=js"
export * from "./SVGIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ffc1f706')) {
      api.createRecord('ffc1f706', component.options)
    } else {
      api.reload('ffc1f706', component.options)
    }
    module.hot.accept("./SVGIcon.vue?vue&type=template&id=ffc1f706", function () {
      api.rerender('ffc1f706', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SVGIcon.vue"
export default component.exports